class Chat{
	constructor(el){
		this.el = $(el);
		this.el_m = this.el.find('ul');
		this.btn_send = this.el.find('.send-message');
		this.inputField = this.el.find('input');
		this.scroll = this.el.find('.scroll');
		this.btn_refresh = this.el.find('.chat-refresh');
		
		this.init();
		this.events();

	}
	init(){
		this.scroll.slimScroll({ height: '200px' });
		this.loadMessages();
	}
	events(){
		this.btn_send.on('click', (e) => {
			e.stopPropagation(); e.stopImmediatePropagation(); e.preventDefault();
			$(e.currentTarget).closest('.chat').data('handler').sendMessage();
		});
		this.btn_refresh.on('click', () => {
			this.scrollDown();
			this.loadMessages();
		});
		this.inputField.on('keypress', (e)=>{
			if (e.key === 'Enter' && this.inputField.val() !== ''){
				this.sendMessage();
			}
		});
	}
	findJSON(type){
		let post;
		switch (type){
		case 'get':
			post = this.btn_send.attr('data-get');
			post = JSON.parse(post);
			return post;
		case 'post':
			post = this.btn_send.attr('data-post');
			post = JSON.parse(post);
			return post;
		}
	}
	scrollDown(){
		let bottom = this.scroll.find('.chats').css('height');
		this.scroll.slimScroll({
			scrollTo : bottom  
		});
	}
	loadMessages(){
		let data = this.findJSON('get');
		$(this.el_m).load('/app/chat/get_messages/', Util.serialize(data), ()=>this.scrollDown());
	}
	sendMessage(){
		let data = this.findJSON('post');
		data.text = this.el.find('input').val();
		this.el.find('input').val('');
		let chat = this;
		$.ajax({
			url: Util.blackbox('/app/dynproc/'),
			processData: false,
			type: 'post',
			dataType: 'json',
			data: Util.serialize(data),
			success: function success(data) {
				if (data.status) {
					chat.loadMessages();
				} else {
					SWAL.error(data);
				}
			}
		});
	}
}